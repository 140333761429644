export default function({ app }) {
  const cookieBotUrl = 'https://consent.cookiebot.com/uc.js'
  app.head.script.push({
    src: cookieBotUrl,
    async: true,
    'data-cbid': app.$themeSettings.cookiebot.id,
    'data-blockingmode': 'auto',
    type: 'text/javascript',
    id: 'Cookiebot',
  })
}
