
  import { mapActions } from 'vuex'
  import Button from '~/components/general/elements/Button'
  import Row from '~/components/layout/Row'
  import WCol from '~/components/layout/WCol'

  export default {
    name: 'PageNotFound',
    components: { WCol, Row, Button },
    async mounted () {
      let path = location.pathname

      if(this.$route) {
        path = this.$route.path
      }

      try {
        await this.createRedirect({
          url: path
        })
      } catch (e) {
        this.$log.error(e)
      }
    },
    methods: {
      ...mapActions({
        createRedirect: 'general/createRedirectRecord'
      })
    }
  }
