import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import {pick} from 'accept-language-parser'
import { setDefaultLocale } from '~/services/DateService'
import { stripTrailingSlash } from '~/services/Helpers'
import { localizeRouteName } from '~/services/RouterService'

export default ({ app, redirect, route, store, query, params, req }) => {
  const acceptedCurrencies = ['CZK', 'EUR']
  const acceptedLanguages = ['cs', 'en']

  if(process.server) {
    let preferredLanguage = pick(acceptedLanguages, req.headers['accept-language'] || '') || 'cs'
    let preferredCurrency = preferredLanguage === 'cs' ? 'CZK' : 'EUR'

    if('currency' in query && acceptedCurrencies.includes(query.currency)) {
      preferredCurrency = query.currency
    }
    if('language' in query && acceptedLanguages.includes(query.language)) {
      preferredLanguage = query.language
    }

    store.commit('general/SETTINGS_SET', {
      language: preferredLanguage,
      currency: preferredCurrency
    }, {root: true})
    app.i18n.setLocale(preferredLanguage)
    setDefaultLocale(preferredLanguage)
  }
  // if('lang' in query && ['en', 'cs'].includes(query.lang)) {
  //   store.commit('general/SETTINGS_SET', {language: query.lang}, {root: true})
  //   console.log(`QUERY::${query.lang}`)
  // }
  //
  const lang = store.getters['general/getLanguage']

  if(!process.client) {
    if(!('language' in query && acceptedLanguages.includes(query.language)) && route.name && route.name.includes('__')) {
      const routeParts = route.name.split('__')

      if(isString(routeParts[1]) && acceptedLanguages.includes(routeParts[1])) {
        store.commit('general/SETTINGS_SET', {language: routeParts[1]}, {root: true})
        app.i18n.setLocale(routeParts[1])
      }
    }
  } else if(route.name && route.name.includes('__') && !route.name.includes(`__${lang}`)) {
      const routeName = route.name.split('__')[0]

      const localizedRoute = app.router.resolve({
        name: localizeRouteName(routeName, lang),
        query,
        params
      })

      const strippedRoutePath = stripTrailingSlash(route.path)

      if (localizedRoute && !isEmpty(localizedRoute.resolved.matched)) {
        const strippedLocalizedRoutePath = stripTrailingSlash(localizedRoute.resolved.path)

        if (strippedRoutePath !== strippedLocalizedRoutePath) {
          redirect(localizedRoute.resolved)
        }
      }
    }
}
